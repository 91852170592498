<template>
    <div class="page flex-col">
        <header>
            <van-nav-bar
                    title="我的钱包"
                    left-text=""
                    left-arrow
                    @click-left="onClickLeft"
            />
        </header>

        <div class="block_1 flex-col">
            <div class="group_2 flex-col">
                <!--<span class="text_6">收益记录</span>-->

                <div class="section_1 flex-row" v-for="(user, index) in constants" :key="index">
                    <div class="block_2 flex-col">
                        <img src="./assets/img/huiyuan.png" alt="">
                    </div>
                    <div class="text-wrapper_2 flex-col justify-between">
                        <span class="text_7">{{user.lowerAccount}}</span>
                        <span class="text_8">{{user.integralDesc}}</span>
                    </div>
                    <div class="text-wrapper_3 flex-col justify-between">
                        <span class="text_9">{{user.symbol}}{{user.integralValue}}</span>
                        <span class="text_10">{{user.createTime}}</span>
                    </div>
                </div>

            </div>
            <div class="group_3 flex-col">
                <div class="list_1 flex-row">
                    <div class="text-wrapper_8 flex-col justify-between" v-for="(item, index) in loopData0"
                         :key="index">
                        <span class="text_19" v-html="item.lanhutext"></span>
                        <span class="text_20" v-html="item.lanhutextName"></span>
                    </div>
                </div>
                <div class="text-wrapper_9 flex-col" @click="cancelTx()"><span class="text_21" >扫码提现</span></div>
            </div>
        </div>
    </div>
</template>
<script>

    import {queryUserByAccount,queryMemberSy} from "@/api/user";

    export default {
        data() {
            return {
                memberAccount: '',
                memberId:'',
                loopData0: [
                   /* {lanhutext0: '0', lanhutext1: '养生积分'},
                    {lanhutext0: '0', lanhutext1: '美食积分'},
                    {lanhutext0: '10', lanhutext1: '分享积分'},
                    {lanhutext0: '0', lanhutext1: '分享积分'}*/
                ],
                constants: [],
                healthIntegral:0,
                foodIntegral:0,
                type1: {
                    lanhutext: 0,
                    lanhutextName: ''
                },
                type2: {
                    lanhutext: 0,
                    lanhutextName: ''
                }
            };
        },
        mounted() {
            this.loopData0 = []
            const user = localStorage.getItem('userInfoDs')
            if (user) {
                this.memberAccount = JSON.parse(user).account
                this.memberId = JSON.parse(user).memberId
                queryUserByAccount(this.memberAccount).then(response => {
                    if (response.code == 200) {
                        this.foodIntegral = response.data.foodIntegral  //美食积分
                        this.healthIntegral = response.data.healthIntegral // 养生积分
                        this.queryMemberSy(this.memberAccount)
                    }
                });
            } else {
                this.$router.push('/login')
            }
        },
        methods: {
            queryMemberSy(memberAccount) {
                queryMemberSy(memberAccount).then(response => {
                    if (response.code == 200) {
                        this.constants = response.data
                        console.log("constants:",this.constants);
                        this.type1.lanhutext = this.foodIntegral
                        this.type1.lanhutextName = '美食积分'
                        this.loopData0.push(this.type1)

                        this.type2.lanhutext = this.healthIntegral
                        this.type2.lanhutextName = '养生积分'
                        this.loopData0.push(this.type2)

                    }
                })

            },
            onClickLeft() {
                this.$router.push('/wode')
            },
            cancelTx() {
                this.$model({
                    show: true,
                    title: "提示",
                    content: "请前往门店，打开微信扫一扫，扫描门店二维码申请提现",
                    confirmButton: false,
                    cancelButton: true
                });
                return false
            }
        }
    };
</script>
<style scoped lang="css" src="./assets/index.rem.css"/>